<template>
  <div>
    <tab-headers>
      Your Profile
      <template #text> Update your profile here </template>
    </tab-headers>
    <div class="bg-white shadow rounded-md">
      <a-tabs
        size="small"
        :style="{ width: '100%' }"
        :tab-position="width > 640 ? 'top' : 'left'"
        default-active-key="1"
        @change="changeTab"
      >
        <a-tab-pane key="1" tab="Personal Information"> </a-tab-pane>
        <a-tab-pane key="2" tab="Security"> </a-tab-pane>
      </a-tabs>
    </div>
    <div class="bg-white mt-4 shadow rounded p-3">
      <div v-if="activeTab === 1">
        <div class="px-4 pt-4 pb-6">
          <div
            class="
              shadow
              rounded
              w-full
              md:flex
              items-center
              justify-start
              px-4
              mb-5
            "
          >
            <div class="w-3/12 py-2">
              <a-upload
                name="avatar"
                list-type="picture-card"
                class="avatar-uploader"
                :show-upload-list="false"
                @change="handleChange"
              >
                <img v-if="imageUrl" :src="imageUrl" alt="avatar" />
                <div v-else>
                  <a-icon :type="loading ? 'loading' : 'plus'" />
                  <div class="ant-upload-text">Upload</div>
                </div>
              </a-upload>
            </div>
            <div class="w-auto">
              <h2 class="font-semibold">Profile Image</h2>
              <div class="mb-2 md:mb-0">
                Click on the Image to upload/change profile image
              </div>
            </div>
          </div>
          <a-form-model layout="vertical">
            <div class="grid md:grid-cols-2 grid-cols-1 gap-4">
              <a-form-item label="First Name">
                <a-input
                  v-model="userCopy.firstName"
                  placeholder="First Name"
                ></a-input>
              </a-form-item>
              <a-form-item label="Last Name">
                <a-input
                  v-model="userCopy.lastName"
                  placeholder="Last Name"
                ></a-input>
              </a-form-item>
              <a-form-item label="Middle Name">
                <a-input
                  v-model="userCopy.otherName"
                  placeholder="Middle Name"
                ></a-input>
              </a-form-item>
              <a-form-item label="Gender">
                <a-select v-model="userCopy.gender" placeholder="Gender">
                  <a-select-option value="male">Male</a-select-option>
                  <a-select-option value="female">Female</a-select-option>
                </a-select>
              </a-form-item>
              <a-form-item label="Date of Birth">
                <a-input
                  v-model="userCopy.dateOfBirth"
                  type="date"
                  placeholder="Date of Birth"
                ></a-input>
              </a-form-item>
              <a-form-item label="Email Address">
                <a-input
                  v-model="userCopy.email"
                  disabled
                  type="email"
                  placeholder="Email Address"
                ></a-input>
              </a-form-item>
              <a-form-item label="Phone Number">
                <a-input
                  v-model="userCopy.phone"
                  type="text"
                  placeholder="Phone Number"
                ></a-input>
              </a-form-item>
              <a-form-item label="Address">
                <a-input
                  v-model="userCopy.address"
                  placeholder="Address"
                ></a-input>
              </a-form-item>
            </div>
          </a-form-model>
          <a-button
            :loading="updatingUsers"
            type="primary"
            @click="updateProfile"
            >Save</a-button
          >
        </div>
      </div>
      <div v-if="activeTab === 2">
        <div class="px-4 pt-4 pb-6">
          <h3 class="font-semibold text-lg mb-2">Change Password</h3>
          <a-form-model
            ref="securityForm"
            :rules="rules"
            :model="password"
            layout="vertical"
          >
            <a-form-model-item has-feedback label="Old Password" prop="'old">
              <a-input
                v-model="password.old"
                type="password"
                placeholder="Old Password"
              ></a-input>
            </a-form-model-item>
            <a-form-model-item has-feedback label="New Password" prop="new">
              <a-input
                v-model="password.new"
                type="password"
                placeholder="New Password"
              ></a-input>
            </a-form-model-item>
            <a-form-model-item
              has-feedback=""
              label="Confirm New Password"
              prop="confirm"
            >
              <a-input
                v-model="password.confirm"
                type="password"
                placeholder="Retype New Password"
              ></a-input>
            </a-form-model-item>
          </a-form-model>
          <a-button
            :loading="password.updating"
            type="primary"
            @click="changePassword"
            >Update</a-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import userApi from '../../api/user'
import authApi from '../../api/auth'
import TabHeaders from '../../components/typography/TabHeaders.vue'

function getBase64(img, callback) {
  const reader = new FileReader()
  reader.addEventListener('load', () => callback(reader.result))
  reader.readAsDataURL(img)
}
export default {
  name: 'LawyerProfile',
  components: { TabHeaders },
  layout: 'userdashboard',
  data() {
    const passwordValidator = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('Please input the password'))
      } else {
        if (this.password.confirm !== '') {
          this.$refs.securityForm.validateField('conform')
        }
        callback()
      }
    }
    const confirmPasswordValidator = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('Please input the password again'))
      } else if (value !== this.password.new) {
        callback(new Error("Password inputs don't match!"))
      } else {
        callback()
      }
    }
    return {
      mode: 'top',
      loading: false,
      updatingUsers: false,
      imageUrl: '',
      states: [],
      towns: [],
      specialty: [],
      loadingProof: false,
      loadingBarCert: false,
      userCopy: {},
      activeTab: 1,
      password: {
        old: '',
        new: '',
        confirm: '',
        updating: false,
      },
      rules: {
        old: [
          {
            message: 'Please enter you present password',
            required: true,
          },
        ],
        new: [
          {
            message: 'Password cannot be empty',
            required: true,
          },
          {
            message: 'Password must be at least 6 characters',
            min: 6,
          },
          {
            trigger: 'change',
            validator: passwordValidator,
          },
        ],
        confirm: [
          {
            required: true,
            message: 'Enter your password again',
          },
          {
            validator: confirmPasswordValidator,
            trigger: 'change',
          },
        ],
      },
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
    width() {
      return window.innerWidth > 0 ? window.innerWidth : screen.width
    },
  },
  mounted() {
    const x = JSON.parse(JSON.stringify(this.user))

    this.userCopy = x

    this.imageUrl = this.userCopy.avatar
  },
  created() {
    this.$store.dispatch('auth/getProfileUpdate')
  },
  methods: {
    changeTab(tab) {
      this.activeTab = Number(tab)
    },
    async changePassword() {
      this.password.updating = true
      const req = await authApi(this.axios).changePassword({
        oldPassword: this.password.old,
        password: this.password.new,
        confirmPassword: this.password.confirm,
      })
      if (req.error) {
        this.$notification.error({
          message: 'Unable to update profile',
          description: req.message,
        })
        this.password.updating = false
        return
      }
      this.$notification.success({
        message: 'Password changed successful',
        description: req.message,
      })
      this.password.updating = false
      this.password = {
        new: '',
        old: '',
        confirm: '',
      }
    },
    async updateProfile() {
      this.updatingUsers = true
      const req = await userApi(this.axios).updateProfile({
        data: this.userCopy,
      })
      if (req.error) {
        this.$notification.error({
          message: 'Unable to update Profile',
          description: req.message,
        })
        this.updatingUsers = false
        return
      }
      this.$store
        .dispatch('auth/getProfileUpdate')
        .then((res) => {
          const x = JSON.parse(JSON.stringify(res.data))

          this.userCopy = x
        })
        .catch((err) => {
          this.$notification.error({
            message: 'Ops',
            description: err.message,
          })
        })
      this.updatingUsers = false
      this.$notification.success({
        message: 'Successful',
        description: req.message,
      })
    },
    handleChange(info) {
      if (info.file.status === 'uploading') {
        this.loading = true
        return
      }
      if (info.file.status === 'done') {
        // Get this url from response in real world.
        this.userCopy.avatar = info.file.originFileObj
        getBase64(info.file.originFileObj, (imageUrl) => {
          this.imageUrl = imageUrl
          this.loading = false
        })
      }
    },
  },
}
</script>

<style>
.avatar-uploader > .ant-upload {
  width: 128px;
  height: 128px;
}
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
</style>
